// Chat Module Styles 

.app-main-header {
  &.no-background {
    background-color: none !important;
  }
}

body.ios-mobile-view-height {
  height: inherit !important;
}

.chat-module {
  @include flex();

  &:before {
    left: -29px;
  }
}

.chat-module-box {
  @include box-shadow($shadow-lg);
  @include display-flex(flex, row, nowrap);
  @include flex(1);
  background-color: $gray-100;
  position: relative;
  z-index: 2;
  width: 100%;
}

.chat-sidenav {
  @include display-flex(flex, column, nowrap);
  @include flex(0, 1, 315px);
  min-width: 315px;
  border-right: solid 1px $gray-200;
}

.chat-box {
  @include display-flex(flex, column, nowrap);
  @include flex(1);
  max-width: 100%;
}

.chat-sidenav-header {
  @include display-flex(flex, column, nowrap);
  padding: 25px 20px 12px;
  background-color: lighten($gray-100, 0.5%);
}

.chat-user-hd {
  @include display-flex();
  margin-bottom: 25px;
}

.chat-sidenav-header .search-wrapper {
  @include display-flex(flex, column, nowrap);
  margin-bottom: 0;

  & .search-bar.right-side-icon {
    @include flex();

    & .form-control {
      @include box-shadow(0 0 3px rgba(0, 0, 0, 0.3));
      @include border-radius(0);
      padding-left: 42px;
      height: 42px;
    }

    & .search-icon {
      left: 10px;
      top: 0;
      height: 42px;
      padding: 0;
    }
  }
}

.chat-avatar {
  min-width: $size-50;

  .chat-user-item & {
    padding: 0 5px;
    min-width: 10px;
  }

  .chat-user-hd & {
    cursor: pointer;
  }
}

.chat-avatar-mode {
  position: relative;
  max-width: $size-50;

  & .chat-mode {
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    z-index: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%;

    &.small {
      width: 8px;
      height: 8px;
    }

    &.online {
      background-color: map_get($green, base);
      @extend %online-effect;
    }

    &.offline {
      background-color: $white;
      border: solid 1px map_get($green, base);
      @extend %online-effect;
    }

    &.away {
      background-color: map_get($yellow, darken-1);
      @extend %away-effect;
    }
  }

  .chat-main-header-info & {
    max-width: $size-60;
  }
}

.chat-sidenav-content {
  background-color: $white;
}

.chat-sidenav-title {
  padding: 10px 16px;
  font-size: $font-size-base;
  color: $info;
  @include display-flex(flex, column, nowrap);
  @include justify-content(center);
}

.chat-tabs-header {
  background-color: lighten($gray-100, 0.5%) !important;
}

.chat-user {
  @include display-flex(flex, column, nowrap);

  &.chat-user-center {
    @include justify-content(center);
    @include align-items(center);

    & .chat-avatar {
      margin-left: auto;
    }
  }
}

.chat-user-item {
  border-bottom: solid 1px $gray-200;
  padding: 16px;
  @include display-flex(flex, column, nowrap);
  @include justify-content(center);
  cursor: pointer;
  max-height: 96px;

  &.active,
  &:hover {
    background-color: lighten($app-primary, 45%);
  }
}

.chat-main-content {
  margin-bottom: 210px !important;
}

.chat-main-content:first-child {
  padding-top: 30px;
}

.chat-user-row {
  margin: 0 -5px;
  @include align-items(center);
}

.chat-info {
  padding: 0 5px;

  & p {
    margin-bottom: 0;
  }

  & .h4 {
    display: block;
    margin-bottom: 3px;
  }
}

.chat-info-des {
  color: $gray-600;
  font-size: 13px;
}

.chat-date {
  padding: 0 5px;
  text-align: right;
}

.chat-contact-col {
  @extend .chat-info;
}

.chat-box-main {
  @include display-flex(flex, column, nowrap);
  @include justify-content(center);
  height: 100%;
}

.module-default {
  @include display-flex(flex, column, nowrap);
  @include justify-content(center);
  @include align-items(center);
  height: 100%;
  padding: 15px;
}

.s-128 {
  font-size: 128px !important;
  width: 128px !important;
  height: 128px !important;
  line-height: 128px !important;
  margin-bottom: 12px;
}

.chat-main {
  @include display-flex(flex, column, nowrap);
  height: 100%;
}

.chat-main-header {
  @include display-flex();
  @include align-items(center);
  border-bottom: solid 1px $gray-200;
  padding: 16px;
  background-color: $white;
  width: 100%;
}

.chat-main-header-info {
  @include display-flex();
  @include align-items(center);
  margin-right: auto;
}

.chat-contact-name {
  font-size: 20px;
  font-weight: 500;
}

.chat-main-footer {
  @include display-flex(flex, column, nowrap);
  border-top: solid 1px $gray-200;
  padding: 16px;
  background-color: $white;
  width: 100%;
  height: 150px;
  padding: 20px 25px;

  & .form-group {
    margin-bottom: 0;
  }
}

.todo-main-footer {
  padding: 6px 16px;
}

.chat-item {
  padding: 16px;
  padding-left: 20%;
  padding-right: 20%;

  & .bubble {
    padding: 8px 12px;
    background-color: $white;
    @include box-shadow(0 0 2px rgba(0, 0, 0, .13));
    @include border-radius(20px 20px 20px 0px);
    position: relative;
    margin-left: 16px;
    max-width: 600px;

    /* &:before,
     &:after {
       content: "";
       position: absolute;
       z-index: 1;
       width: 0;
       height: 0;
     }

     &:before {
       border-top: 9px solid transparent;
       border-bottom: 9px solid transparent;

       border-right: 9px solid $gray-300;
       left: -9px;
       top: 5px;
     }

     &:after {
       border-top: 8px solid transparent;
       border-bottom: 8px solid transparent;

       border-right: 8px solid $white;
       left: -8px;
       top: 6px;
     }*/
  }

  & .bubble-transparent-receive {
    padding: 4px 10px;
    position: relative;
    margin-right: 45px;
    //margin-left: 16px;
    //max-width: 600px;

    & .message {
      /*font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      color: #575757;
      text-align: left;*/

      font-family: "Source Sans Pro", Helvetica, Arial, Sans-Serif;
      color: #2E2D33;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0;
      font-weight: 400;
      transition: .2s cubic-bezier(.55, 0, .1, 1);
      margin-top: 0;
      margin-bottom: 0;
      white-space: pre-wrap;
    }
  }

  & .bubble-transparent-sent {
    padding-right: 26px;
    padding-top: 4px;
    position: relative;
    margin-left: 90px;
    word-break: break-all;
    //margin-left: 16px;
    //max-width: 600px;

    & .message {
      /*font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      color: #575757;
      text-align: left;*/

      font-family: "Source Sans Pro", Helvetica, Arial, Sans-Serif;
      color: #2E2D33;
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: 0;
      font-weight: 400;
      transition: .2s cubic-bezier(.55, 0, .1, 1);
      margin-top: 0;
      margin-bottom: 0;
      white-space: pre-wrap;
    }
  }

  &.flex-row-reverse {
    & .bubble {
      margin-right: 16px;
      margin-left: 0;
      background-color: lighten($app-primary, 45%);
      @include border-radius(20px 20px 0 20px);

      /*&:before {
        left: auto;
        right: -9px;
        border-left: 9px solid $gray-300;
        border-right: 0 none;
      }

      &:after {
        left: auto;
        right: -8px;
        border-left: 8px solid lighten($app-primary, 45%);
        border-right: 0 none;
      }*/
    }
  }
}

.chat-btn {
  font-size: 38px !important;
  margin: 0 10px 0 -8px;
}

.chat-textarea {
  height: 100px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background-color: transparent;
  outline: 0 !important;
  resize: none;
  overflow: auto;
  font-size: 1.7rem;
  padding-top: 26px;
  @include box-shadow(none);
  @include border-radius(0);
}

.chat-textarea::placeholder {
  font-size: 1.7rem;
}

.chat-textarea:focus {
  outline: 0 !important;
  @include box-shadow(none);
}

.last-message-time {
  font-size: 11px;
  color: $gray-500;
}

.chat-number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #d8d8d8;
  font-size: 1.3rem;
}

.chat-main-footer-col {
  padding-right: 5px;
}

@media screen and (max-width: 1399px) {
  .chat-module {
    margin: -24px -45px -23px;
    padding-top: 0;
  }

  .s-128 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
}

@media screen and (max-width: 1199px) {
  .chat-module {
    margin: -24px -45px -23px;
    padding-top: 0;
  }

  .chat-main-header {
    padding: 8px 24px;

    & .size-60 {
      height: 50px !important;
      width: 50px !important;
      line-height: 50px;
    }

    & button {
      margin-bottom: 0;
    }
  }

  .chat-main-header-info {
    & .chat-avatar-mode {
      max-width: 50px;
    }
  }

  .chat-contact-name,
  .chat-sidenav-title {
    font-size: 16px;
  }

  .s-128 {
    font-size: 128px !important;
    width: 128px !important;
    height: 128px !important;
    line-height: 128px !important;
  }

  .chat-number-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #d8d8d8;
    font-size: 1.1rem;
  }

  .chat-main-footer-col {
    padding-right: 7px;
    padding-left: 7px;
  }

  .chat-textarea {
    font-size: 1.2rem;
    padding-left: 100px;
    padding-top: 34px;
  }

  .chat-textarea::placeholder {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 991px) {
  .chat-module {
    margin: -24px -46px -23px;
    padding-top: 0;
  }

  .s-128 {
    font-size: 100px !important;
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
    margin-bottom: 10px;
  }

  .chat-main-footer-col {
    padding-right: 3px;
    padding-left: 3px;
  }

  .chat-textarea {
    font-size: 1.2rem;
    padding-left: 120px;
    padding-top: 34px;
  }

  .chat-textarea::placeholder {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 830px) {
  .chat-module {
    margin: -24px -46px -23px;
    padding-top: 0;
  }

  .chat-sidenav-title,
  .chat-contact-name {
    font-size: 15px;
  }

  .s-128 {
    font-size: 80px !important;
    width: 80px !important;
    height: 80px !important;
    line-height: 80px !important;
  }

  .chat-main-footer-col {
    padding-right: 2px;
    padding-left: 2px;
  }

  .chat-number-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 47px;
    height: 40pt;
    border-radius: 10px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
  }

  .chat-main-footer-col {
    padding-right: 3px;
  }

  .chat-textarea {
    font-size: 30px;
    padding-left: 20px;
    padding-top: 0px;
  }

  .chat-textarea::placeholder {
    font-size: 40px;
  }
}

@media screen and (max-width: 680px) {
  .chat-module {
    margin: -24px -36px -23px;
    padding-top: 0;
  }

  .module-default {
    h1 {
      font-size: 17px;
    }
  }

  .s-128 {
    font-size: 40px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }

  .chat-item {
    padding: 10px;
    padding-left: 10%;
    padding-right: 10%;

    & .bubble-transparent-receive {
      margin-right: 20px;

      & .message {
        font-size: 1.2rem;
      }
    }

    & .bubble-transparent-sent {

      & .message {
        font-size: 1.2rem;
      }
    }
  }

  .chat-main-content:first-child {
    padding-top: 12px;
  }

  .chat-main-footer {
    height: 120px;
  }

  .chat-number-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #d8d8d8;
    font-size: 1rem
  }

  .chat-main-footer-col {
    padding-right: 2px;
    padding-left: 2px;
  }

  .chat-textarea {
    font-size: 40px;
    padding-left: 0px;
    padding-top: 0px;
  }

  .chat-textarea::placeholder {
    font-size: 40px;
  }
}

@media screen and (max-width: 550px) {
  .chat-number-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #d8d8d8;
    font-size: 1rem;
  }

  .chat-main-footer-col {
    padding-right: 2px;
    padding-left: 2px;
  }

  .chat-textarea {
    font-size: 30px;
    padding-left: 2px;
    padding-top: 0px;
  }

  .chat-textarea::placeholder {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .chat-number-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #d8d8d8;
    font-size: 1rem;
  }

  .chat-main-footer-col {
    padding-right: 1px;
    padding-left: 1px;
  }
}

@media screen and (max-width: 300px) {
  .chat-number-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #d8d8d8;
    font-size: 0.9rem;
  }

  .chat-main-footer-col {
    padding-right: 1px;
    padding-left: 1px;
  }
}

/* IE 10, 11 HACK */
.chat-inputs {
  max-width: 800px;
}

@media all and (-ms-high-contrast:none) {
  .chat-inputs {
    max-width: 1000px;
  }

  .col {
    flex-basis: 100%;
  }

  /* IE10 */
  *::-ms-backdrop,
  .col {
    flex-basis: 100%;
  }

  /* IE11 */
}